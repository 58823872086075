import React, { useEffect, useState } from "react";
import ChatUi from "./components/pages/ChatUi/ChatUi";
import LandingPage from "./components/pages/LandingPage/LandingPage";
import TermsAndConditions from "./components/pages/TermsAndConditions/TermsAndConditions";
import { AuthService } from "./services/AuthService";
import GuidanceInfoContent from "./components/pages/Info/GuidanceInfoContent";
import UserGuideInfoContent from "./components/pages/Info/UserGuideInfoContent";
import FaqInfoContent from "./components/pages/Info/FaqInfoContent";
import { InfoTemplate } from "./components/pages/Info/InfoTemplate";
import LoadingPage from "./components/pages/LoadingPage/LoadingPage";
import {
  useSetUserInfoContext,
  useUserInfoContext,
} from "./contexts/UserInfoContext";
import useTermsAndConditions from "./hooks/api/useTermsAndConditions";
import { supportedLanguages } from "./utils/displayValues";

function App() {
  const [authState, setAuthState] = useState<
    "idle" | "codeExchange" | "refreshing"
  >("idle");
  const [termsAccepted, setTermsAccepted] = useState<boolean>(true);
  const userInfo = useUserInfoContext();
  const setUserInfo = useSetUserInfoContext();

  const { getTerms } = useTermsAndConditions();

  useEffect(() => {
    const codeMatch = window.location.href.match("[?#&]code=([^&]*)");
    window.history.replaceState({}, "", window.location.pathname);

    (async () => {
      if (codeMatch?.[1]) {
        setAuthState("codeExchange");
        const pingIdJson = await AuthService.getAccessToken(codeMatch[1]);
        AuthService.setRefreshTokenCookie(pingIdJson.refreshToken);

        const { response, error } = await getTerms(pingIdJson.accessToken);

        if (response) {
          setUserInfo(pingIdJson);
          setTermsAccepted(response.accepted);
        } else {
          AuthService.unsetRefreshTokenCookie();
          setTimeout(() => alert(error), 1);
        }
        setAuthState("idle");
      } else {
        setAuthState((prevState) =>
          prevState === "idle" ? "refreshing" : prevState,
        );
        const refresh_token = AuthService.getRefreshTokenCookie();
        if (refresh_token) {
          setUserInfo({
            accessToken: "",
            refreshToken: refresh_token,
            expiresAt: 0,
          });
        }
        setAuthState((prevState) =>
          prevState === "refreshing" ? "idle" : prevState,
        );
      }
    })();
  }, [setUserInfo, setAuthState, getTerms]);

  useEffect(() => {
    if (userInfo?.accessToken === "" && !!userInfo?.refreshToken) {
      AuthService.refreshToken().then((response) => {
        setUserInfo(response);
      });
    }
  }, [userInfo?.accessToken, userInfo?.refreshToken, setUserInfo]);

  const [language, setLanguage] = useState(supportedLanguages.en);

  // ROUTING
  if (window.location.pathname === "/user-guide") {
    return (
      <InfoTemplate
        showLanguageSelection={true}
        language={language}
        setLanguage={setLanguage}
      >
        <UserGuideInfoContent language={language} />
      </InfoTemplate>
    );
  }

  if (window.location.pathname === "/gen-ai-guidance") {
    return (
      <InfoTemplate
        showLanguageSelection={true}
        language={language}
        setLanguage={setLanguage}
      >
        <GuidanceInfoContent language={language} />
      </InfoTemplate>
    );
  }

  if (window.location.pathname === "/faq") {
    return (
      <InfoTemplate
        showLanguageSelection={false}
        language={language}
        setLanguage={setLanguage}
      >
        <FaqInfoContent />
      </InfoTemplate>
    );
  }

  if (authState !== "idle" || userInfo?.accessToken === "") {
    return <LoadingPage />;
  }

  if (!userInfo) {
    return <LandingPage />;
  }

  if (!termsAccepted) {
    return (
      <TermsAndConditions
        language={language}
        setLanguage={setLanguage}
        setTermsAccepted={setTermsAccepted}
      />
    );
  }

  return <ChatUi />;
}

export default App;
